import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'

const IntroContainer = styled.div`
  margin: 2em auto;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: 0px 3px 8px #a9a9a9;
  padding: 1em;
  @media (max-width: 930px) {
    margin: 2em 0;
  }
`
const Intro = () => {
  return (
    <StaticQuery
      query={introQuery}
      render={data => {
        const { author } = data.site.siteMetadata
        return (
          <IntroContainer>
            <h1>Congratulations!</h1>
            <p>
              We are excited to have you start your ascent towards DevMountain.
              Before class starts, there are a few things to know that will help
              you get set up for success come your first day of class.
            </p>
            <p>
              Below are three sections, <strong>Developer Environment</strong>,{' '}
              <strong>HTML & CSS</strong>, and <strong>JavaScript</strong>. Work
              through each section and build a strong foundation of knowledge
              for your time at DevMountain. There is a saying we love here and
              it is "You cannot be prepared enough." Working through each
              section will make sure that you are prepared for the first day of
              class and have you ready to hit the ground running.
            </p>
            <p>
              Again, we are so jazzed to have you start your journey towards
              becoming a web developer and we look forward to seeing you on day
              one!
            </p>
            <div>
              <p>With Love,{''}</p>
              <Image fixed={data.file.childImageSharp.fixed} alt={author} />
            </div>
          </IntroContainer>
        )
      }}
    />
  )
}

const introQuery = graphql`
  query IntroQuery {
    site {
      siteMetadata {
        author
      }
    }
    file(absolutePath: { regex: "/logo_dark_lettering.png/" }) {
      childImageSharp {
        fixed(width: 243) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Intro
