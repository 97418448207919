import React from 'react'
import { graphql } from 'gatsby'

import Intro from '../components/Intro'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Sections from '../components/Sections'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[
            `DevMountain`,
            `html`,
            `javascript`,
            `css`,
            `learn to code`,
          ]}
        />
        <Intro />
        <Sections />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
